import React, { useState } from "react";
import { Link } from "react-router-dom";

const RegisterTemp: React.FC = () => {
    const [message, setMessage] = useState<string>("");

    const [input, setInput] = useState({
        username: "",
        password: "",
        email: ""
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    };

    const resetForm = () => {
        setInput({
            username: "",
            password: "",
            email: ""
        });
        setMessage("");
    };

    const onSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const res = await fetch('https://api.motivul.com:8080/api/user', {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                "username": input.username,
                "password": input.password,
                "email": input.email
            }),
        });

        const data = await res.json();

        if (data == null) return;

        if (data.username || data.token) {
            if (data.token) {
                localStorage.setItem("token", data.token);
            }
            resetForm();
            setMessage("Successfully registered!");
        }
        
        if (data.msg) setMessage(data.msg);
    }

    return (
        <div>
            <h2>TEMPORARY register page</h2>
            <p>This is the TEMPORARY register page.</p>
            <p>Want the <Link to="/">landing page?</Link></p>
            <br/>

            {!sessionStorage.getItem("loggedIn") ?
                <>
                    <form onSubmit={onSubmit}>
                        <input type="text" placeholder="Username" value={input.username} onChange={handleChange} name="username" /><br/>
                        <input type="password" placeholder="Password" value={input.password} onChange={handleChange} name="password" /><br/>
                        <input type="text" placeholder="Email" value={input.email} onChange={handleChange} name="email" /><br/>
                        <input type="submit" value="Register" />
                    </form>
                    <br/>
                    <div className="message">{message}</div>
                </>
                :
                <></>
            }
        </div>
    );
};

export default RegisterTemp;
