import React, { useState } from "react";
import { Link } from "react-router-dom";

const Landing: React.FC = () => {
    const [message, setMessage] = useState<string>("");

    const [input, setInput] = useState({
        username: "",
        password: ""
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    };
    const onSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const res = await fetch('https://api.motivul.com:8080/api/login', {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                "username": input.username,
                "password": input.password
            }),
        });

        const data = await res.json();
        
        if (data.msg) setMessage(data.msg);
        if (data.token) {
            localStorage.setItem("token", data.token);
            window.location.reload();
        }
    }

    return (
        <div>
            <h2>Landing Page</h2>
            <p>This is the landing page. Log-in form should eventually be in a modal</p>
            <p>Want the <Link to="/registertemp">temporary register page?</Link></p>
            <br/>

            {!sessionStorage.getItem("loggedIn") ?
                <>
                    <form onSubmit={onSubmit}>
                        <input type="text" placeholder="Username" value={input.username} onChange={handleChange} name="username" /><br/>
                        <input type="password" placeholder="Password" value={input.password} onChange={handleChange} name="password" /><br/>
                        <input type="submit" value="Login" />
                    </form>
                    <br/>
                    <div className="message">{message}</div>
                </>
                :
                <></>
            }
        </div>
    );
};

export default Landing;
